import Vue from 'vue'
import VueRouter from 'vue-router'
import {Auth} from 'aws-amplify';
import {Role} from "@/constants/role";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        meta:{
            title: 'Login',
            roles: ['ALL']
        },
        component: () => import('../modules/core/login/login.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta:{
            title: 'Register',
            roles: ['ALL']
        },
        component: () => import('../modules/core/register/register.vue')
    },
    {
        path: '/forgot',
        name: 'forgot',
        meta:{
            title: 'Forgot Password',
            roles: [Role.ALL]
        },
        component: () => import('../modules/core/forgot/forgot.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta:{
            title: 'Reset password',
        },
        component: () => import('../modules/core/reset-password/reset-password.vue')
    },
    {
        path: '/activate-account',
        name: 'activate-account',
        meta:{
            title: 'Activate account',
        },
        component: () => import('../modules/core/activate-account/activate-account.vue')
    },
    {
        path: '/change-initial-password',
        name: 'change-initial-password',
        props: true,
        meta:{
            title: 'Change initial password',
        },
        component: () => import('../modules/core/change-initial-password/change-initial-password.vue')
    },
    {
        path: '/not-found',
        name: 'not-found',
        meta:{
            title: 'Not found',
            roles: [Role.ALL],
        },
        component: () => import('../modules/core/not-found/not-found.vue')
    },
    {
        path: '/user-management',
        name: 'user-management',
        meta:{
            title: 'Users',
            roles: [Role.SYS_ADMIN, Role.SYS_ACCOUNT_MANAGER, Role.CLIENT_ACCOUNT_MANAGER, Role.SYS_MANAGER],
            icon: 'account-multiple-outline'
        },
        component: () => import('../modules/users/users.vue')
    },
    {
        path: '/organizations-page',
        name: 'organizations-page',
        meta:{
            title: 'Organizations',
            roles: [Role.SYS_ADMIN, Role.SYS_MANAGER],
            icon: 'domain'
        },
        component: () => import('../modules/organizations/organizations-page.vue')
    },
    {
        path: '/projects',
        name: 'projects',
        meta:{
            title: 'Projects',
            roles: [Role.SYS_ADMIN, Role.SYS_MANAGER],
            icon: 'clipboard-file-outline'
        },
        component: () => import('../modules/projects/projects-page.vue')
    },
    {
        path: '/devices',
        name: 'devices',
        meta:{
            title: 'Devices',
            roles: [Role.SYS_ADMIN, Role.SYS_MANAGER],
            icon: 'cctv'
        },
        component: () => import('../modules/devices/devices-page.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta:{
            title: 'Dashboard',
            roles: [Role.SYS_ADMIN, Role.CLIENT_WORKSITE_MANAGER, Role.SYS_MANAGER, Role.SYS_USER],
            icon: 'view-dashboard-outline'
        },
        component: () => import('../modules/dashboard/dashboard.vue')
    },
    {
        path: '/monitoring-page/:id?',
        name: 'monitoring-page',
        meta:{
            title: 'Monitoring',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER],
            icon: 'monitor-eye'
        },
        props:true,
        component: () => import('../modules/monitoring/monitoring.vue')
    },
    {
        path: '/installation-data/:id?',
        name: 'installation-data',
        props:true,
        meta:{
            title: 'Installation data',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER],
            icon: 'connection'
        },
        component: () => import('../modules/installation-data/installation-data.vue')
    },
    {
        path: '/video-gaps-page/:projectId',
        name: 'video-gaps-page',
        props:true,
        meta:{
            title: 'Video gaps',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER],
        },
        component: () => import('../modules/video-gaps-page/video-gaps-page.vue')
    },
    {
        path: '/live-tracker/:projectId',
        name: 'live-tracker',
        props:true,
        meta:{
            title: 'Live tracker',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER, Role.SYS_USER],
        },
        component: () => import('../modules/live-tracker/live-tracker.vue')
    },
    {
        path: '/live-tracker-double/:projectId',
        name: 'live-tracker-double',
        props:true,
        meta:{
            title: 'Live tracker',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER, Role.SYS_USER],
        },
        component: () => import('../modules/live-tracker/live-tracker-double.vue')
    },
    {
        path: '/incident-details/:incidentId',
        name: 'incident-details',
        props:true,
        meta:{
            title: 'Incident details',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER, Role.SYS_USER],
        },
        component: () => import('../modules/incident-details/incident-details.vue')
    },
    {
        path: '/line-alarm/:projectId',
        name: 'line-alarm',
        props:true,
        meta:{
            title: 'Line alarm',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER, Role.SYS_USER],
            icon: 'alarm-light'
        },
        component: () => import('../modules/line-alarm/line-alarm.vue')
    },
    {
        path: '/live-line-alarm/:projectId',
        name: 'live-line-alarm',
        props:true,
        meta:{
            title: 'Live line alarm',
            roles: [Role.SYS_ADMIN, Role.SYS_OPERATOR, Role.SYS_MANAGER],
            icon: 'alert-rhombus'
        },
        component: () => import('../modules/live-line-alarm/live-line-alarm.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
/**
 * Auth check if user is loged in
 */
router.beforeEach(async (to, from, next) => {
    //Disable check just for these routes
    if (to.name === 'login' || to.name === 'register' || to.name === 'forgot' || to.name === 'reset-password'
        || to.name === 'not-found' || to.name === 'activate-account'|| to.name === 'change-initial-password') {
        //NOT Authorized
        next()
    } else {
        //Authorized
        await Auth.currentAuthenticatedUser().then((user) => {
            console.log('Logged user', user.getSignInUserSession().getIdToken().payload.given_name)
            let params = user.getSignInUserSession().getIdToken().payload.given_name
            if(isAllowedRoute(params, to.meta.roles)){
                next();
            }else{
                next({name: 'not-found'})
            }
        }).catch((error) => {
            //NOT Authorized
            console.log(error)
            if(error.message === 'Unauthorized'){
                next({name: 'not-found'})
            }else{
                next({name: 'login'})
            }

        });
    }
})

function isAllowedRoute (params, roles){
    console.log(roles)
    if(params){
        let prm = params.split('@')
        if(prm.length === 2){
            if(roles.includes(prm[1])){
                return true
            }
        }
    }

    return false
}
export default router
