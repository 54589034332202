/**
 * Menu for every page on layout
 */
export default {
    '/dashboard': [
        {
        title: "Dashboard",
        url: "/dashboard"
        }
    ]
}
