<template src="./template.html"></template>

<script>
/* eslint-disable */
 import editProfile from '@/modules/core/edit-profile/edit-profile'
import userDropdown from "@/components/user-dropdown/user-dropdown.vue";
import ConnectionIndicators from "@/components/connection-indicators/connection-indicators.vue";
export default {
  name: 'LayoutDefault',
  components:{
    ConnectionIndicators,
     editProfile,
    userDropdown
  },
  props: {
    layout: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isLoading:false,
      isEditProfileActive:false,
      layoutMenu:[],
      subMenu:[],
      currentPath:''
    };
  },
  /**
   * Mounted
   */
  mounted:function(){

  },
  methods: {
    /**
     * Logout Cognito
     */
    logout() {
      this.isLoading = true;
      this.$auth.signOut().then(() => {
        this.$router.push('/')
        this.isLoading = false;
      })
    },
    checkIfItemIsActive(item){

      console.log(this.currentPath)
      try{
        if(item.path === this.currentPath){
          return true
        }else {
          return false
        }
      }catch (e) {
        return false;
      }
    },
    checkIfSubmenu(item){
      if("submenu" in item) {
        if(item.submenu === true){
          return true
        }else {
          return false
        }
      }else {
        return false
      }
    },
    getAllWhereSubCategory(){
      let subMenuItems = []
      let fullMenu = [];
      if(this.layoutMenu) {
        this.layoutMenu.forEach((item) => {
          if ("submenu" in item) {
            if (item.submenu === true) {
              if (subMenuItems.includes(item.submenuTitle) === false) {
                subMenuItems.push(item.submenuTitle)
              }
            }
          }
        })
      }

      subMenuItems.forEach((item)=>{
        let subMenu = []
        this.layoutMenu.forEach((item2)=>{
          if(item2.submenuTitle === item){
            subMenu.push(item2)
          }
        })
        fullMenu.push({
          title:this.layoutMenu.find(x => x.submenuTitle === item).submenuTitle,
          submenu:subMenu
        })
      })

      this.subMenu = fullMenu


    }
  },
  watch: {
    /**
     * Get new many data when route is changed
     * @param to
     * @param from
     */
    $route(to, from) {
      let store =  this.$store.getters.getMenuLayout

      this.currentPath = to.path;

      let path_cleaned = to.path.split('/')
      let path = '';
      path_cleaned.forEach((item, index) => {
        if(item !== ''){
          if(item.split('-').length < 4){
            if(item.length < 20){
              path = path + '/' + item
            }
          }
        }
      })
      this.layoutMenu = store[path]
      this.getAllWhereSubCategory();

      let app = 'Worker Safety'
      document.title = app+' - '+to.meta.title;
    }
  }
};
</script>
<style scoped>
.menuItemBold{
  font-weight: bold!important;
}
</style>
