<template>
  <div>
    <b-tooltip v-if="this.$store.state.socketState.connected" label="Listening for realtime events" type="is-dark" position="is-bottom">
      <b-icon icon="wifi-check" type="is-primary"></b-icon>
    </b-tooltip>
    <b-tooltip v-else label="Socket disconnected!" type="is-dark" position="is-bottom">
      <b-icon icon="wifi-alert" type="is-warning"></b-icon>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: "connection-indicators"
}
</script>

<style scoped>

</style>