import Vue from "vue";
import moment from "moment";

export default {
    /**
     * Get line alarms for device between dates
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    getLineAlarms: async ({commit}, data) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/line-alarm/between/${data.deviceName}/${data.startTime}/${data.endTime}/ON`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveLineAlarms', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    gelLastDayLineAlarms: async ({commit}, data) => {
        let start = moment.utc().startOf('day')
        let end = moment.utc()

        let startMelbourne = moment(start).subtract(10, 'hours').toISOString()
        let endMelbourne = moment(end).subtract(10, 'hours').toISOString()

        console.log('Selected start date M: ', startMelbourne)
        console.log('Selected end date M: ', endMelbourne)
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/line-alarm/between/${data.deviceName}/${startMelbourne}/${endMelbourne}/ON`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveLiveLineAlarms', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    getLineAlarmById: async ({commit}, id) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/line-alarm/${id}`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    //await commit('saveLineAlarms', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
}
