import Vue from "vue";

export default {
    /*
     * Get health data for all devices in project
     * @param commit
     * @returns {Promise<void>}
     */
    getDetectedObjects: async ({commit}, cameraId) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/object-paths/${cameraId}`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveDetectedObjects', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
}