import Vue from "vue";

export default {
    updateDevice: async ({commit}, data) => {

        let deviceId = data.deviceId
        delete data.deviceId

        let config = {
            method: 'put',
            url: `${process.env.VUE_APP_WSA_API_URL}/device/${deviceId}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('updateDevice', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    /*
 * Get health data for all devices in project
 * @param commit
 * @returns {Promise<void>}
 */
    getHealthData: async ({commit}, projectId) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/device/health/${projectId}`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveHealthData', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    deleteDevice: async ({commit}, device) => {
        return await Vue.prototype.$http({
            method: 'delete',
            url: `${process.env.VUE_APP_WSA_API_URL}/device/${device._id}`,
        })
            .then((response) => {
                if (response.data.success) {
                    commit('deleteDevice', device)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    getDeviceRecordings: async ({commit}, projectId) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/health/analyzeRecordings/${projectId}`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveRecordingsData', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
}