<template>
  <div>
    <editProfile :isEditProfileActive="isEditProfileActive"
                 @editProfileStatus="isEditProfileActive = false"></editProfile>
    <div style="display: flex; margin-top: 16px">
      <div class="media mr-4" v-if="user != null">
        <avatar :user="user"></avatar>
        <div class="media-content" style="margin-left: 8px; margin-top: 12px;">
          <h6 class="is-6 has-text-weight-semibold" style="margin-bottom: 0; line-height: 0.5;">{{ user.fullName}}</h6>
          <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">{{ user.job }}</small>
        </div>
      </div>
      <b-navbar-dropdown :collapsible="true" :boxed="true" style="margin-right: 16px;" :right="true">
        <b-navbar-item @click="isEditProfileActive = true">
          Edit profile
        </b-navbar-item>

        <b-navbar-item @click="logout">
          Logout
        </b-navbar-item>
      </b-navbar-dropdown>
    </div>
  </div>
</template>

<script>
import editProfile from '@/modules/core/edit-profile/edit-profile'
import avatar from "@/components/avatar/avatar.vue";

export default {
  name: "user-dropdown",
  components: {
    editProfile,
    avatar
  },
  data() {
    return {
      isEditProfileActive: false,
    }
  },
  computed:{
    user(){
      return {avatar: null, fullName: this.$store.getters.getUserFullName, job: this.$store.getters.getUserJob}
    }
  },
  methods: {
    logout() {
      this.isLoading = true;
      this.$auth.signOut().then(() => {
        this.$router.push('/')
        this.$store.commit('clearAllData')
        this.isLoading = false;
      })
    },
  }

}
</script>

<style scoped>

</style>