const HealthConstants = {
    LOW_FPS:5.0,
    LOW_BATTERY:11.4,
    GREEN_TEMPERATURE:65.0,
    ORANGE_TEMPERATURE:75.0,
    FPS_PERCENTAGE_GREEN: 10,//percents
    FPS_PERCENTAGE_ORANGE: 20,//percents
    LATENCY_GREEN: 5,//seconds
    LATENCY_ORANGE: 10,//seconds
}

module.exports = HealthConstants

