import Vue from "vue";

export default {
    /**
     * Get all organizations
     * @param commit
     * @returns {Promise<void>}
     */
    getAllOrganizations: async ({commit}) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/organization`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveOrganizations', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    createOrganization: async ({commit}, data) => {
        return await Vue.prototype.$http({
            method: 'post',
            url: `${process.env.VUE_APP_WSA_API_URL}/organization`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then((response) => {
                if (response.data.success) {
                    commit('addOrganization', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    deleteOrganization: async ({commit}, organizationId) => {
        return await Vue.prototype.$http({
            method: 'delete',
            url: `${process.env.VUE_APP_WSA_API_URL}/organization/${organizationId}`,
        })
            .then((response) => {
                if (response.data.success) {
                    commit('deleteOrganization', organizationId)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
}
