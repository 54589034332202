const Role = {
    ALL:'ALL',
    SYS_ADMIN:'SYS_ADMIN',
    SYS_ACCOUNT_MANAGER:'SYS_ACCOUNT_MANAGER',
    SYS_MANAGER: 'SYS_MANAGER',
    SYS_OPERATOR: 'SYS_OPERATOR',
    SYS_USER: 'SYS_USER',
    CLIENT_ACCOUNT_MANAGER:'CLIENT_ACCOUNT_MANAGER',
    CLIENT_WORKSITE_MANAGER:'CLIENT_WORKSITE_MANAGER',
    CLIENT_USER:'CLIENT_USER',
}

function getAvailableRoles(role){
    if(role === Role.SYS_ADMIN){
        return [Role.SYS_ADMIN, Role.SYS_ACCOUNT_MANAGER, Role.SYS_MANAGER, Role.SYS_OPERATOR, Role.SYS_USER, Role.CLIENT_ACCOUNT_MANAGER]
    }else if(role === Role.CLIENT_ACCOUNT_MANAGER){
        return [Role.CLIENT_ACCOUNT_MANAGER, Role.CLIENT_WORKSITE_MANAGER, Role.CLIENT_USER]
    }else if(role === Role.SYS_ACCOUNT_MANAGER){
        return [Role.SYS_ACCOUNT_MANAGER, Role.SYS_MANAGER, Role.SYS_OPERATOR, Role.SYS_USER]
    }

    return []
}

function isDeleteUserAllowed(role, deleteUserRole){
    if(role === Role.SYS_ADMIN){
        if(deleteUserRole === Role.SYS_ADMIN || deleteUserRole === Role.SYS_ACCOUNT_MANAGER
            || deleteUserRole === Role.SYS_MANAGER || deleteUserRole === Role.CLIENT_ACCOUNT_MANAGER || deleteUserRole === Role.SYS_USER){
            return true
        }
    }else if(role === Role.SYS_ACCOUNT_MANAGER){
        if(deleteUserRole === Role.SYS_ACCOUNT_MANAGER
            || deleteUserRole === Role.SYS_MANAGER || deleteUserRole === Role.SYS_USER){
            return true
        }
    }else if(role === Role.CLIENT_ACCOUNT_MANAGER){
        if(deleteUserRole === Role.CLIENT_ACCOUNT_MANAGER || deleteUserRole === Role.CLIENT_WORKSITE_MANAGER || deleteUserRole === Role.CLIENT_USER){
            return true
        }
    }

    return false
}

module.exports = {Role, getAvailableRoles, isDeleteUserAllowed}