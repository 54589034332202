<template src="./template.html"></template>

<script>
export default {
  name: 'edit-profile',
  props: {
    isEditProfileActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      isLoading: false
    }
  },
  methods: {
    /**
     * Close modal
     */
    closeModal() {
      this.$emit('editProfileStatus', false)
    },
    /**
     * Change password amplify
     */
    changePassword() {
      this.isLoading = true
      this.$auth.currentAuthenticatedUser()
          .then(user => {
            return this.$auth.changePassword(user, this.oldPassword, this.newPassword);
          })
          .then((data) => {
            //Success notification
            this.$buefy.toast.open({
              message: 'Password successfully changed',
              type: 'is-success'
            })
            this.isLoading = false
            this.closeModal()
          }).catch((error) => {
        //Failed notification
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
        this.isLoading = false
      });
    }
  }
}
</script>
<style lang="scss" scoped src="./style.scss"></style>
