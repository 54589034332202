import Vue from "vue";

export default {
    /*
     * Get all [project subscriptions]
     * @param commit
     * @returns {Promise<void>}
     */
    getAllSubscriptions: async ({commit}) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/project-subscriptions`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveProjectSubscriptions', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    setSubscription: async ({commit}, data) => {

        let inputData = {
            projectIds: data.projectIds,
            userId: data.userId
        }

        return await Vue.prototype.$http({
            method: 'post',
            url: `${process.env.VUE_APP_WSA_API_URL}/project-subscriptions`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then((response) => {
                if (response.data.success) {
                    commit('mergeSubscriptions', {subscriptions: response.data.data, inputData: inputData})
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
}