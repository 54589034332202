import menu from "@/router/menu";

export default {
    /**
     * Save user data
     * @param state (this state)
     * @param item
     */
    saveUserData(state, item) {
        state.user = item;
    },
    //Organizations
    saveOrganizations(state, organizations) {
        state.organizations = organizations
    },
    addOrganization(state, organization) {
        state.organizations.push(organization)
    },
    deleteOrganization(state, organizationId) {
        let index = state.organizations.findIndex(org => org._id === organizationId)
        if (index !== -1) {
            state.organizations.splice(index, 1)
        }
    },
    //Users
    saveUsers(state, users) {
        state.users = users
    },
    addUser(state, user) {
        state.users.push(user)
    },
    //Projects
    saveProjects(state, projects) {
        state.projects = projects
    },
    saveInstallationProject(state, project) {
        state.installationProject = project
        let index = state.projects.findIndex(prj => prj.project._id === project.project._id)
        if (index !== -1) {
            state.projects[index].devices.forEach(projectDevice => {
                try {
                    let stallazDeviceIndex = project.stallazProject.project.devices.findIndex(dev => dev.propertyGroups[0].properties[3].value === projectDevice.rlhId)
                    if (stallazDeviceIndex !== -1) {
                        let stallazDevice = project.stallazProject.project.devices[stallazDeviceIndex]
                        try {
                            //FIXME Remove this line when stallaz fps config is ready
                            projectDevice.fpsConfig = '10'
                            //let fpsConfig = stallazDevice.propertyGroups[1].properties[3].value

                        } catch (e) {
                            console.log(e)
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            })
        }
    },
    addProject(state, project) {
        state.projects.push(project)
    },
    deleteProject(state, projectId) {
        let index = state.projects.findIndex(project => project.project._id === projectId)
        if (index !== -1) {
            state.projects.splice(index, 1)
        }
    },
    //Devices
    addDevice(state, device) {
        state.streams.push(device)
    },
    deleteDevice(state, device) {
        let index = state.projects.findIndex(prj => prj.project._id === device.projectId)
        if (index !== -1) {
            let project = state.projects[index]
            console.log('Find project: ', project)
            let dIndex = project.devices.findIndex(dvc => dvc._id === device._id)
            if (dIndex !== -1) {
                project.devices.splice(dIndex, 1)
            }
        }
    },
    updateDevice(state, device) {
        console.log('Projects: ', state.projects)
        let index = state.projects.findIndex(prj => prj.project._id === device.projectId)
        if (index !== -1) {
            let project = state.projects[index]
            console.log('Find project: ', project)
            let dIndex = project.devices.findIndex(dvc => dvc._id === device._id)
            if (dIndex !== -1) {
                project.devices.splice(dIndex, 1, device)
            }
        }

    },
    //Project health
    saveHealthData(state, data) {
        state.projectHealthData = data
    },
    saveRecordingsData(state, data) {
        state.deviceRecordings = data
    },
    //Sockets
    setSocketState(state, connected) {
        state.socketState.connected = connected
    },
    addLatencyMessage(state, message) {
        let index = state.ocrMessages.findIndex(ocr => ocr.camera_name === message.camera_name)
        if (index !== -1) {
            state.ocrMessages.splice(index, 1, message)
        } else {
            state.ocrMessages.push(message)
        }
    },
    setObjectPath(state, message) {
        state.objectPath = message
    },
    saveDetectedObjects(state, objects) {
        state.detectedObjects = objects
    },
    //Project subscriptions
    saveProjectSubscriptions(state, projectSubs) {
        state.projectSubscriptions = projectSubs
    },
    mergeSubscriptions(state, data) {
        if (data.subscriptions) {
            if (data.inputData) {
                let subs = state.projectSubscriptions.filter(sub => sub.subscription.userId !== data.inputData.userId)
                state.projectSubscriptions = subs
            }

            if (data.subscriptions.length > 0) {
                state.projectSubscriptions.push(...data.subscriptions)
            }
        }
    },
    //Line alarms
    saveLineAlarms(state, lineAlarms) {
        state.lineAlarms = lineAlarms
    },
    //Line alarms
    saveLiveLineAlarms(state, lineAlarms) {
        state.liveLineAlarms = lineAlarms
    },
    addLiveLineAlarms(state, lineAlarms){
        let newLineAlarms = []
        newLineAlarms.push(...state.liveLineAlarms)
        if(lineAlarms && lineAlarms.length > 0){
            let deviceId = newLineAlarms.length > 0 ? newLineAlarms[0].camera_name : ''
            if(deviceId !== ''){
                lineAlarms.forEach(alarm => {
                    if(alarm.direction === 'ON'){
                        let index = newLineAlarms.findIndex(al => al._id === alarm._id)
                        if(alarm.camera_name === deviceId){
                            if(index === -1){
                                //Add new
                                newLineAlarms.push(alarm)
                                alarm.startBlink = new Date()
                            } else{
                                if(newLineAlarms[index].direction !== alarm.direction && newLineAlarms[index] !== 'ON'){
                                    alarm.startBlink = new Date()
                                }else{
                                    alarm.startBlink = newLineAlarms[index].startBlink
                                }
                                newLineAlarms.splice(index, 1, alarm)
                            }
                        }
                    }
                })

                state.liveLineAlarms = newLineAlarms
                //console.log('AddLineAlarms called: 4', state.liveLineAlarms)
            }
        }
    },
    //General
    clearAllData(state) {
        //User data from cognito
        state.user = null
        state.organizations = []
        state.projects = []
        state.installationProject = null
        state.users = []
        state.projectHealthData = []
        state.allTests = []
        state.socketState = {
            connected: false
        }
        state.deviceRecordings = []
        state.ocrMessages = []
        state.objectPath = null
        state.detectedObjects = []
        state.currentIncident = null
        state.projectSubscriptions = []
        state.dashboardSelections = {project: null, data:null}

    }
};
