import Vue from "vue";

export default {
    /**
     * Get all organizations
     * @param commit
     * @returns {Promise<void>}
     */
    getAllUsers: async ({commit}) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/auth/listUsers`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveUsers', response.data.data.Users)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    getUsersForAccountManager: async ({commit}) => {
        let config = {
            method: 'get',
            url: `${process.env.VUE_APP_WSA_API_URL}/auth/listUsers/accountManager/ALL`
        };
        return await Vue.prototype.$http(config)
            .then(async (response) => {
                if (response.data.success) {
                    await commit('saveUsers', response.data.data)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    createUser: async ({commit}, data) =>{
        return await Vue.prototype.$http({
            method: 'post',
            url: `${process.env.VUE_APP_WSA_API_URL}/auth/signUp`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then((response) => {
                if (response.data.success) {
                    commit('addUser', response.data.data.User)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    },
    resendConfirmationPassword: async ({commit}, data) =>{
        return await Vue.prototype.$http({
            method: 'post',
            url: `${process.env.VUE_APP_WSA_API_URL}/auth/signUp/resendPass`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        })
            .then((response) => {
                if (response.data.success) {
                    console.log('Resend response success: ', response.data)
                    //commit('addUser', response.data.data.User)
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return error
            });
    }
}