import {Auth} from "aws-amplify";
import HealthConstants from "@/constants/health";
import moment from "moment";

export default {
    /**
     * Get access token
     * @param state
     * @returns {any}
     */
    token: state => {
        return 'Bearer ' + state.user.signInUserSession.idToken
    },

    role: state => {
        let params = state.user.signInUserSession.idToken.payload.given_name
        if(params) {
            let prm = params.split('@')
            if (prm.length === 2) {
                return prm[1]
            }
        }
        return 'UNDEFINED'
    },
    getUserOrganizationId: state => {
        let params = state.user.signInUserSession.idToken.payload.given_name
        if(params) {
            let prm = params.split('@')
            if (prm.length === 2) {
                return prm[0]
            }
        }
        return 'UNDEFINED'
    },
    getUserOrganizationName: state =>{
      return state.user.signInUserSession.idToken.payload['custom:organization']
    },
    getUserFullName: state =>{
        let fullName = `${state.user.signInUserSession.idToken.payload['custom:firstName']} ${state.user.signInUserSession.idToken.payload['custom:lastName']}`
        return fullName
    },
    getUserJob: state =>{
        return state.user.signInUserSession.idToken.payload['custom:job']
    },
    /**
     * Check if user is auth
     * @param state (this state)
     * @returns {boolean}
     */
    isAuth: state => {
        if (state.user) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * Get menu items for specific route
     * @param state
     * @param path
     * @returns {{"/video/download": [{title: string, url: string},{title: string, url: string}], "/video/upload": [{title: string, url: string},{title: string, url: string}]}}
     */
    getMenuLayout: (state,path) => {
        return state.menu
    },

    getUsers:(state) =>{

        let users = []

        if(state.users){
            state.users.forEach(user => {
                console.log('User: ', user)
                let mappedUser = {};
                //TODO find better way to do this extraction
                mappedUser.id = user.Username
                mappedUser.email = user.Attributes.filter(attr => attr.Name === 'email')[0].Value
                mappedUser.firstName = user.Attributes.filter(attr => attr.Name === 'custom:firstName')[0].Value
                mappedUser.lastName = user.Attributes.filter(attr => attr.Name === 'custom:lastName')[0].Value
                mappedUser.status = user.Attributes.filter(attr => attr.Name === 'custom:status')[0].Value
                mappedUser.job = user.Attributes.filter(attr => attr.Name === 'custom:job')[0].Value
                mappedUser.organizationName = user.Attributes.filter(attr => attr.Name === 'custom:organization')[0].Value
                let givenName = user.Attributes.filter(attr => attr.Name === 'given_name')[0].Value
                mappedUser.role = givenName.split('@')[1]
                mappedUser.organization = givenName.split('@')[0]
                mappedUser.createdDate = user.UserCreateDate
                mappedUser.status = user.UserStatus
                users.push(mappedUser)
            })
        }

        return users
    },
    getSelectedInstallationProject: (state) =>{
        return state.installationProject
    },
    getProjectById: (state) => (projectId) =>{
        console.log('Prj id: ', projectId)
        let index =  state.projects.findIndex(prj => prj.project._id === projectId)
        if(index !== -1){
            let project = state.projects[index].project
            console.log('Project by id: ', project)
            return project
        }
    },
    getDevicesForProject: (state) => (projectId) =>{
        if(state.projects.length > 0){
            let index = state.projects.findIndex(prj=>prj.project._id === projectId)
            if(index !== -1){
                console.log('Devices:', state.projects[index].devices)
                return state.projects[index].devices
            }
        }
        return []

    },
    getDeviceByProjectIdAndDeviceId: (state) => (data) =>{
        if(state.projects.length > 0){
            let index = state.projects.findIndex(prj=>prj.project._id === data.projectId)
            if(index !== -1){
                console.log('Devices:', state.projects[index].devices)
                let deviceIndex = state.projects[index].devices.findIndex(dv=>dv._id === data.deviceId)
                if(deviceIndex !== -1){
                    return state.projects[index].devices[deviceIndex]
                }
            }
        }
        return null

    },
    getHealthData: (state) => (deviceId) => {
        if(state.projectHealthData.length > 0){
            let index = state.projectHealthData.findIndex(st => st._id === deviceId)
            if(index !== -1){
                return state.projectHealthData[index].health
            }
        }
    },
    getAllStreamProblems: (state) =>{
        if(state.projectHealthData.length > 0){
            return state.projectHealthData
                .map(healthData => healthData.health)
                .filter(health=>health.fpsOutput === undefined || health.fpsOutput < HealthConstants.LOW_FPS).length
        }
        return '-'
    },
    getAllLowBattery: (state) =>{
        if(state.projectHealthData.length > 0){
            return state.projectHealthData
                .map(healthData => healthData.health)
                .filter(health=>health.rlHealth === undefined || health.rlHealth.health !== 'green').length
        }
        return '-'
    },
    getAllHighTemperature: (state) =>{
        if(state.projectHealthData.length > 0){
            return state.projectHealthData
                .map(healthData => healthData.health)
                .filter(health=>health.rlHealth === undefined || health.rlHealth.temperature > HealthConstants.ORANGE_TEMPERATURE).length
        }
        return '-'
    },
    getLatencyDataForCamera:(state) =>(rlhId) => {

        let noData = {
            currentTime: '--:--',
            cameraTime: '--:--',
            latency: '-',
            iconColor: 'has-text-danger',
            tooltip: 'No data'
        }

        if(state.ocrMessages.length > 0){
            let index = state.ocrMessages.findIndex(ocrMsg=>ocrMsg.camera_name === rlhId)
            if(index !== -1){

                let format = '2023-06-27 08:12:24'

                let currentTime = moment(state.ocrMessages[index].current_time, 'YYYY-MM-DD HH:mm:ss').toDate();
                let cameraTime = moment(state.ocrMessages[index].time_from_camera, 'YYYY-MM-DD HH:mm:ss').toDate();

                let latency = (currentTime.getTime() - cameraTime.getTime()) / 1000
                let latencyColor = 'has-text-danger'
                let latencyTooltip = 'Need maintenance'


                if(latency < HealthConstants.LATENCY_GREEN){
                    latencyColor = 'has-text-primary'
                    latencyTooltip = 'All good'
                }else if(latency < HealthConstants.LATENCY_ORANGE){
                    latencyColor = 'has-text-warning'
                    latencyTooltip = 'Warning'
                }

                return {
                    currentTime: currentTime.toLocaleTimeString(),
                    cameraTime: cameraTime.toLocaleTimeString(),
                    latency: latency,
                    iconColor: latencyColor,
                    tooltip: latencyTooltip
                }
            }

        }

        return noData
    },
    getGapsCount:(state) =>(rlhId) => {
        if(state.deviceRecordings.length > 0){
            console.log('Dev rec', state.deviceRecordings)
            let index = state.deviceRecordings.findIndex(rec=>rec.id === rlhId)
            if(index !== -1){
                return state.deviceRecordings[index].gaps.length
            }
        }

        return '-'
    },
    getGapsCountPerProject:(state, getters) => (projectId) =>{
        if(state.deviceRecordings.length > 0){
            let devices = getters.getDevicesForProject(projectId)
            console.log('GAPS', devices)
            if(devices && devices.length > 0){
                let gaps = 0
                devices.forEach(device=>{
                    let index = state.deviceRecordings.findIndex(rec=>rec.id === device.rlhId)
                    if(index !== -1){
                        gaps += state.deviceRecordings[index].gaps.length
                    }
                })
                return gaps
            }
        }

        return '0'
    },
    getLatencyCountForProject: (state, getters) => (projectId) =>{
            let devices = getters.getDevicesForProject(projectId)
            console.log('GAPS', devices)
            if(devices && devices.length > 0){
                let latencies = 0
                devices.forEach(device=>{
                    let latencyData = getters.getLatencyDataForCamera(device.rlhId)
                    if(latencyData && latencyData.latency && latencyData.latency > HealthConstants.LATENCY_ORANGE){
                        latencies++;
                    }
                })
                return latencies
            }

        return '0'
    },
    getObjects:(state) =>{
        return state.objectPath
    },
    getDetectedObjectsOrderedByTime:(state) =>{
        if(state.detectedObjects && state.detectedObjects.length > 0){
            let objects = []
            state.detectedObjects.forEach(obj=>{
                obj.sampleAggs.forEach(agg => {
                    agg.objectClass = obj.objectClass
                    objects.push(agg)
                })

            })
            let ordered = objects.sort((x,y)=>{return new Date(x.timestamp) - new Date(y.timestamp)})
            return ordered
        }

        return []
    },
    getSubscribedProjectNamesForUser: (state) => (userId) =>{
        let subscriptions = []
        if(userId && state.projectSubscriptions && state.projectSubscriptions.length > 0){
            console.log('Project subs: ', state.projectSubscriptions)
            let subs = state.projectSubscriptions.filter(sub => sub.subscription !== null && sub.subscription.userId === userId)
            if(subs.length > 0){
                subs.forEach(sub => {
                    subscriptions.push(sub.projectName)
                })
            }
        }
        return subscriptions
    },
    getSubscribedProjectsIdsForUser: (state) => (userId) =>{
        let projectsIds = []
        if(userId && state.projectSubscriptions && state.projectSubscriptions.length > 0){
            let subs = state.projectSubscriptions.filter(sub => sub.subscription.userId === userId)
            if(subs.length > 0){
                subs.forEach(sub => {
                    projectsIds.push(sub.projectId)
                })
            }
        }
        return projectsIds
    },
    getLiveLineAlarms: (state) => {
        let lineAlarms = []
        if(state.liveLineAlarms.length > 0){
            lineAlarms = state.liveLineAlarms.sort((a1,a2) => {return new Date(a2.created_at) - new Date(a1.created_at)})
        }

        return lineAlarms
    }


}
